import './App.css';
import React from 'react';

import SceneManager from './SceneManager';

import * as THREE from 'three';
import {SSAARenderPass} from 'three/examples/jsm/postprocessing/SSAARenderPass';
import {UnrealBloomPass} from 'three/examples/jsm/postprocessing/UnrealBloomPass';
import {AfterimagePass} from 'three/examples/jsm/postprocessing/AfterimagePass';
import {FilmPass} from 'three/examples/jsm/postprocessing/FilmPass';

import gentilis_bold from 'three/examples/fonts/gentilis_bold.typeface.json';
import gentilis_regular from 'three/examples/fonts/gentilis_regular.typeface.json';
import helvetiker_bold from 'three/examples/fonts/helvetiker_bold.typeface.json';
import helvetiker_regular from 'three/examples/fonts/helvetiker_regular.typeface.json';
import optimer_bold from 'three/examples/fonts/optimer_bold.typeface.json';
import optimer_regular from 'three/examples/fonts/optimer_regular.typeface.json';
import droid_sans_bold from 'three/examples/fonts/droid/droid_sans_bold.typeface.json';
import droid_sans_regular from 'three/examples/fonts/droid/droid_sans_regular.typeface.json';
import droid_serif_bold from 'three/examples/fonts/droid/droid_serif_bold.typeface.json';
import droid_serif_regular from 'three/examples/fonts/droid/droid_serif_regular.typeface.json';

// import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls';

import TWEEN from '@tweenjs/tween.js';

const config = {
    postProcessing: true,
    colors: {
        ambientLight: 0x444444,
        directionalLight: 0xcccccc,
    },
    strings: [
        'PHP', 'HTML', 'CSS', 
        'JavaScript', 'NodeJS', 
        'Java', 'Java (Android)', 
        'C++', 'C', 'C#', 
        'React Native', 'React', 
        'MySQL', 
        'Picture editing', 
        'Video editing', 
        'Digital Audio Work'
    ]
};

export default class App extends React.Component
{
    constructor(props)
    {
        super(props);

        this.state = {};

        window.onresize = () => {
            this.manager.width = window.innerWidth;
            this.manager.height = window.innerHeight;
            this.manager.onResize();
        };
    }
  
    componentDidMount()
    {
        this.manager = new SceneManager(this.update.bind(this), window.innerWidth, window.innerHeight);
        this.renderer = this.manager.renderer;
        this.camera = this.manager.camera;
        this.scene = this.manager.scene;

        // this.renderer.setClearColor(0x000000, 0);
        this.scene.background = new THREE.Color(0x020509);

        if(config.postProcessing)
        {
            const postProcessingStack = [];

            // with EffectComposer, "antialias: true" of WebGLRenderer (which uses MSAA) won't work...
            // Antialias with SSAARenderPass will look better than MSAA but it costs more peformance
            const ssaaRenderPass = new SSAARenderPass(/* scene, camera, clearColor, clearAlpha */this.scene, this.camera);
            postProcessingStack.push(ssaaRenderPass);

            const unrealBloomPass = new UnrealBloomPass(/* resolution, strength, radius, threshold */256, .5, 1.2, 0);
            postProcessingStack.push(unrealBloomPass);

            const afterimagePass = new AfterimagePass(.1);
            postProcessingStack.push(afterimagePass);

            const filmPass = new FilmPass(/* noiseIntensity, scanlinesIntensity, scanlinesCount, grayscale */.4, .6, 4096, 0);
            postProcessingStack.push(filmPass);

            this.manager.addPostProcessingStack(postProcessingStack);
        }
        
        this.camera.up = new THREE.Vector3(0, 1, 0);
        this.camera.position.z = 1;

        //====Controls====
        // this.controls = new OrbitControls(this.camera, this.renderer.domElement);
        // // { // auto rotate
        // //     this.controls.autoRotate = true;
        // //     this.controls.autoRotateSpeed = 1;
        // //     this.controls.target = new THREE.Vector3();
        // // }
        // // { // panning mode
        // //     // 0 - ScreenSpacePanning (default)
        // //     // 1 - HorizontalPanning
        // //     this.controls.panningMode = 1;
        // // }
        // // { // damping
        // //     this.controls.enableDamping = true;
        // //     this.controls.dampingFactor = .2;
        // // }
        //====/Controls====
  
        this.contentElement.appendChild(this.renderer.domElement);
    
        this.setupScene();
        this.manager.start();
    }
  
    componentWillUnmount()
    {
        this.manager.stop();
        this.contentElement.removeChild(this.renderer.domElement);
    }

    setupScene()
    {
        {
            let light = new THREE.AmbientLight(config.colors.ambientLight);
            this.scene.add(light);

            light = new THREE.DirectionalLight(config.colors.directionalLight, 1);
            light.position.set(5, 5, 5);
            light.target.position.set(0, 0, 0);
            light.castShadow = true;
            light.shadow.camera.near = .01;
            light.shadow.camera.far = 15;
            light.shadow.camera.fov = 45;
            light.shadow.camera.left = -8;
            light.shadow.camera.right =  8;
            light.shadow.camera.top =  5;
            light.shadow.camera.bottom = -5;
            //light.shadow.cameraVisible = true;
            light.shadow.bias = .001;
            light.shadow.mapSize.width = 1024;
            light.shadow.mapSize.height = 1024;
            this.scene.add(light);
        }

        const fontLoader = new THREE.FontLoader();
        const fonts = [
            gentilis_bold,
            gentilis_regular,

            helvetiker_bold,
            helvetiker_regular,

            optimer_bold,
            optimer_regular,

            droid_sans_bold,
            droid_sans_regular,

            droid_serif_bold,
            droid_serif_regular,
        ];
        const font = fontLoader.parse(fonts[6]);

        this.textMeshes = [];
        this.textMeshHitboxes = [];
        const center = this.camera.position.clone();
        const radius = 20;
        for(const text of config.strings)
        {
            const i = this.textMeshes.length-1;
            let _randomCalls = 0;
            const random = () => Math.abs(Math.sin(Math.pow(i + (++_randomCalls), 2)));

            const textGeometry = new THREE.TextBufferGeometry(
                text,
                {
                    font,
                    size: random()*.5 + 2,
                    height: .2,
                    curveSegments: 6,
                    bevelEnabled: true,
                    bevelThickness: .2,
                    bevelSize: .02,
                    bevelOffset: 0,
                    bevelSegments: 4
                }
            );
            textGeometry.originalText = text; // store the text 
            textGeometry.center(); // to center the text on the axis
            const textMaterial = new THREE.MeshNormalMaterial();
            const mesh = new THREE.Mesh(textGeometry, textMaterial);
            this.textMeshes.push(mesh);
            this.scene.add(mesh);

            //====Pos====
            let isColliding = false;
            do
            {
                //==random axis==
                const axis = new THREE.Vector3(
                    random()-.5, 
                    random()-.5, 
                    random()-.5
                ).normalize();
                mesh.position.copy(axis.multiplyScalar(radius));

                //==geographical==
                // const latitude = Math.asin(2 * random() - 1);
                // const longitude = 2*Math.PI * random();
                // mesh.position.x = Math.cos(latitude) * Math.cos(longitude) * radius;
                // mesh.position.y = Math.cos(latitude) * Math.sin(longitude) * radius;
                // mesh.position.z = Math.sin(latitude) * radius;

                mesh.position.add(center);
                mesh.lookAt(center);
                mesh.rotation.y += random() * Math.PI/2 - Math.PI/4; // vary rotation a bit

                //====Collision Detection====
                mesh.geometry.computeBoundingBox();
                mesh.updateMatrixWorld();
                for(let i = 0; i < this.textMeshes.length-1; i++)
                {
                    const comparingMesh = this.textMeshes[i];
                    comparingMesh.geometry.computeBoundingBox();
                    comparingMesh.updateMatrixWorld();
    
                    const box1 = mesh.geometry.boundingBox.clone();
                    box1.applyMatrix4(mesh.matrixWorld);
    
                    const box2 = comparingMesh.geometry.boundingBox.clone();
                    box2.applyMatrix4(comparingMesh.matrixWorld);
    
                    isColliding = box1.intersectsBox(box2);
                    if(isColliding)
                        break;
                }
                //====Collision Detection====
            } while(isColliding);

            //==Axis-Aligned Bounding Box (AABB)==
            // {
            //     const helper = new THREE.BoxHelper(mesh, 0xff0000);
            //     helper.update();
            //     this.scene.add(helper);
            // }

            //==Oriented Bounding Box (OBB)==
            {
                const geometry = new THREE.BoxGeometry();
                const hitbox = new THREE.Mesh(geometry, new THREE.MeshBasicMaterial({color: 0xff0000, wireframe: true}));
                mesh.geometry.boundingBox.getSize(hitbox.scale);
                hitbox.applyMatrix4(mesh.matrixWorld);
                
                hitbox.userData.textMesh = mesh;
                // mesh.userData.hitbox = hitbox; // as userData will be cloned, a circular referencing may result in: Uncaught RangeError: Maximum call stack size exceeded at JSON.stringify (<anonymous>) at Mesh.toJSON (three.module.js:7149)
                mesh.hitbox = hitbox;
                this.textMeshHitboxes.push(hitbox);

                hitbox.visible = false;
                this.scene.add(hitbox);
            }
            //====/Pos====
        }

        // after all meshes were added, reposition the ones that do not fit in the camera frustum (may happen on mobile)
        {
            const camera = this.camera.clone(); // do not use the real camera because it will rotate a lot now
            // const getFrustumHeightAtDistance = distance => 2 * distance * Math.tan(.5 * camera.fov * Math.PI/180);            
            const getDistanceAtFrustumHeight = frustumHeight => frustumHeight * .5 / Math.tan(.5 * camera.fov * Math.PI/180);
            // const getFOVForFrustumHeightAndDistance = (frustumHeight, distance) => 2 * Math.atan(frustumHeight * .5 / distance) * 180/Math.PI;
            // const frustumWidth = frustumHeight * camera.aspect;
            // const frustumHeight = frustumWidth / camera.aspect;

            for(const mesh of this.textMeshes)
            {
                camera.lookAt(mesh.position);
                mesh.geometry.computeBoundingBox();
                mesh.updateMatrixWorld();
                const box = mesh.geometry.boundingBox.clone();
                const objectSizes = box.getSize(new THREE.Vector3());
    
                const desiredFrustumWidth = objectSizes.x;
                const desiredFrustumHeight = desiredFrustumWidth / camera.aspect;
    
                const distance = Math.max(
                    radius,
                    getDistanceAtFrustumHeight(desiredFrustumHeight) * 1.1
                );
    
                mesh.position.normalize().multiplyScalar(distance);
            }
        }

        this.camera.lookAt(this.textMeshes[3].position);

        this.targetObject = this.camera.clone();
        this.focusObject();

        const el_info = document.createElement('div');
        el_info.style.position = 'absolute';
        el_info.style.bottom = '.5em';
        el_info.style.left = '.5em';
        el_info.style.maxWidth = 'calc(100% - 1em)';
        el_info.style.overflow = 'hidden';
        el_info.style.fontSize = '4em';
        el_info.style.fontFamily = 'monospace';
        el_info.style.textShadow = '0 0 4px #000000';
        el_info.style.pointerEvents = 'none';
        el_info.style.userSelect = 'none';
        this.contentElement.appendChild(el_info);
        this.el_info = el_info;

        this.initInteraction();
    }
    
    initInteraction()
    {
        // let isMouseDown = false;
        // const start = e => {
        //     if(e.button === 0)
        //     {
        //         isMouseDown = true;
        //     }
        // };
        // const move = e => {
        //     if(isMouseDown)
        //     {
                
        //     }
        // };
        // const end = e => {
        //     isMouseDown = false;
        // };

        // this.renderer.domElement.addEventListener('mousedown', start);
        // this.renderer.domElement.addEventListener('touchstart', start);

        // this.renderer.domElement.addEventListener('mousemove', move);
        // this.renderer.domElement.addEventListener('touchmove', move);

        // this.renderer.domElement.addEventListener('mouseup', end);
        // this.renderer.domElement.addEventListener('touchend', end);

        const raycaster = new THREE.Raycaster();
        const mouse = new THREE.Vector2();

        const onClick = event => {
            event.preventDefault();

            mouse.set(
                (event.clientX / window.innerWidth) * 2 - 1,
                -(event.clientY / window.innerHeight) * 2 + 1
            ); // 2D coordinates of the mouse in normalized device coordinates (NDC)
            raycaster.setFromCamera(mouse, this.camera);

            // const intersects = raycaster.intersectObjects(this.textMeshes);
            const intersects = raycaster.intersectObjects(this.textMeshHitboxes);
            if(intersects.length > 0)
            {
                const intersect = intersects[0];
                // const mesh = intersect.object;
                const mesh = intersect.object.userData.textMesh;

                this.selectObject(mesh);
            }
        }
        this.renderer.domElement.addEventListener('click', onClick, false);
    }

    async writeInfo(info, speed = 100)
    {
        this._info = info;
        let i = 0;
        while(this.el_info.innerHTML !== info)
        {
            if(this._info !== info) // there was a newer call to writeInfo so we just stop this one
                break;

            if(info.length <= 0)
                i = 0;

            this.el_info.innerHTML = 
                this.el_info.innerHTML.substr(0, Math.min(i, info.length)) + 
                (i < info.length ? info.charAt(i) : '') + 
                this.el_info.innerHTML.substr(i + 1);

            i++;
            await new Promise(r => setTimeout(r, speed));
        }
    }

    async disposeInfo(speed = 60)
    {
        const info = '';
        this._info = info;

        const indices = Object.keys(this.el_info.innerHTML.split('')).map(i => parseInt(i, 10));
        //==Shuffle==
        for(let i = indices.length - 1; i > 0; i--)
        {
            const j = Math.floor(Math.random() * (i + 1));
            [indices[i], indices[j]] = [indices[j], indices[i]];
        }
        //==/Shuffle==
        for(const i of indices)
        {
            if(this._info !== info)
                break;

            const currentText = this.el_info.innerHTML.replace(/&nbsp;/g, "\u00a0");
            this.el_info.innerHTML = (currentText.substr(0, i) + (currentText.charAt(i) !== ' ' ? "\u00a0" : ' ') + currentText.substr(i + 1));

            await new Promise(r => setTimeout(r, speed));
        }
        this.el_info.innerHTML = '';
    }

    animateRotation(sourceMesh, targetMesh, duration)
    {
        const startQ = new THREE.Quaternion().copy(sourceMesh.quaternion);
        const endQ = new THREE.Quaternion().copy(targetMesh.quaternion);
        const currentQ = new THREE.Quaternion();

        const source = {value: 0};
        return new TWEEN.Tween(source)
            .to({value: 1}, duration)
            .easing(TWEEN.Easing.Sinusoidal.InOut)
            .onUpdate(() => {
                // interpolate quaternions with the current tween value
                currentQ.slerpQuaternions(startQ, endQ, source.value);

                currentQ.normalize();
                sourceMesh.rotation.setFromQuaternion(currentQ);
                // if(sourceMesh.userData.hitbox)
                //     sourceMesh.userData.hitbox.rotation.setFromQuaternion(currentQ);
                if(sourceMesh.hitbox)
                    sourceMesh.hitbox.rotation.setFromQuaternion(currentQ);
            });
    }

    hasSelection()
    {
        return !!this.selection;
    }

    selectObject(mesh)
    {
        if(this.hasSelection())
        {   
            const stop = mesh === this.selection.mesh; 
            this.deselectObject(stop);
            if(stop)
                return;
        }

        const text = mesh.geometry.originalText;
        this.writeInfo(text);

        const duration = this.focusObject(mesh);

        const target = mesh.clone();
        target.lookAt(this.camera.position);

        this.selection = {
            mesh,
            originalQuaternion: mesh.quaternion.clone(),
            tween: this.animateRotation(mesh, target, duration)
                .start()
        };
    }

    deselectObject(resetInfo = true)
    {
        if(this.hasSelection())
        {
            this.selection.tween.stop();
            this.animateRotation(this.selection.mesh, {quaternion: this.selection.originalQuaternion}, 2000)
                .start();
            this.selection = null;
            if(resetInfo)
                this.disposeInfo();
        }
        this.focusObject();
    }

    focusObject(mesh)
    {
        if(this.tweenRotation)
            this.tweenRotation.stop();

        if(!mesh)
            mesh = this.textMeshes[Math.floor(Math.random() * this.textMeshes.length)];

        this.targetObject.lookAt(mesh.position);
        const maxDuration = 12000;
        //====Euler Rotation====
        // const rotationDistance = this.camera.rotation.toVector3().sub(this.targetObject.rotation.toVector3()).length();
        // const duration = Math.min(maxDuration, rotationDistance*maxDuration/4);
        // this.tweenRotation = new TWEEN.Tween(this.camera.rotation)
        //     .to({
        //         x: this.targetObject.rotation.x,
        //         y: this.targetObject.rotation.y,
        //         z: this.targetObject.rotation.z
        //     }, duration)
        //     .easing(TWEEN.Easing.Sinusoidal.InOut)
        //     .onComplete(() => {
        //         this.tweenRotation = null;
        //         if(!this.selected)
        //             this.focusObject();
        //     })
        //     .start();
        //====/Euler Rotation====
        //====Quaternion Rotation====
        const rotationDistance = this.camera.quaternion.angleTo(this.targetObject.quaternion);
        const duration = Math.min(maxDuration, rotationDistance*maxDuration/Math.PI);
        this.tweenRotation = this.animateRotation(this.camera, this.targetObject, duration)
            .onComplete(() => {
                this.tweenRotation = null;
                if(!this.hasSelection())
                    this.focusObject();
            })
            .start();
        //====/Quaternion Rotation====
        return duration;
    }
  
    update(delta, now)
    {
        TWEEN.update(); // updates all Tweens
    }
    
    render()
    {
        return (
            <div ref={mount => {this.contentElement = mount;}} />
        );
    }
}
