import * as THREE from 'three';
import {EffectComposer} from 'three/examples/jsm/postprocessing/EffectComposer';
import {RenderPass} from 'three/examples/jsm/postprocessing/RenderPass';

export default class SceneManager
{
    constructor(onUpdate, width, height)
    {
        this.onUpdate = onUpdate;
        this.width = width;
        this.height = height;

        this.lastTime = null; // last time in milliseconds
        this.frameDuration = 1000/60; // target 60fps

        this.scene = new THREE.Scene();
        this.camera = new THREE.PerspectiveCamera(75, width / height, .1, 1000);
        this.renderer = new THREE.WebGLRenderer({antialias: true, alpha: true});
        this.renderer.setSize(width, height);

        this.executeRender = () => this.renderer.render(this.scene, this.camera);

        this.animate = this.animate.bind(this);
    }

    onResize()
    {
        this.camera.aspect = this.width / this.height;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(this.width, this.height);
        this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    }
  
    addPostProcessingStack(postProcessingStack)
    {
        //====Post-Processing====
        if(postProcessingStack.length)
        {
            const composer = new EffectComposer(this.renderer);
            const renderPass = new RenderPass(this.scene, this.camera);
            composer.addPass(renderPass);

            for(const pass of postProcessingStack)
                composer.addPass(pass);

            this.executeRender = () => composer.render();
        }
        //====Post-Processing====

        this.renderer.shadowMap.enabled = true;
    }
  
    start()
    {
        if(!this.frameId)
            this.animate(Date.now());
    }
  
    stop()
    {
        cancelAnimationFrame(this.frameId);
    }
  
    animate(now/*DOMHighResTimeStamp in milliseconds */)
    { 
        this.frameId = window.requestAnimationFrame(this.animate);

        this.lastTime = this.lastTime || now - this.frameDuration; // initialize lastTime with "now - frameDuration"
        const delta = Math.min(200, now - this.lastTime);
        this.lastTime = now;

        this.onUpdate(delta/1000, now);
        this.render();
    }
  
    render()
    {
        this.executeRender();
    }
}
